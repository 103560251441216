.no-wrap {
  white-space: nowrap;
}

.preserve-spaces {
  white-space: pre;
}

.homepage-img {
  width: 22em;
}

.img-description {
  font-size: 0.7em;
  color: var(--text-color);
}

@media screen and (max-width: 1000px) {
  .homepage-img {
    max-width: 85%;
  }
}

.waving-hand {
  animation: wave-animation 2.5s infinite;
  transform-origin: 70% 70%;
  display: inline-block;
  width: 1.5em;
  position: relative;
}

.waving-hand::before {
  content: "👋";
}

.waving-hand:hover {
  cursor: grab;
  animation: none;
}

.waving-hand:hover::before {
  content: "✋";
}

.waving-hand:hover::after {
  content: "🙌";
  transform-origin: center center;
  animation: high-five-animation 1s forwards;
  position: absolute;
  top: 0;
  left: 0.2em;
}

.waving-hand.-no-high-five::after {
  content: "";
  animation: none;
}

.highlight {
  text-decoration: none;
  position: relative;
  transition: color var(--transition);
  display: inline-block;
}

.highlight::after {
  content: "";
  position: absolute;
  z-index: -1;
  left: -0.1em;
  right: -0.1em;
  bottom: 0;
  transition: top 200ms cubic-bezier(0, 0.85, 0.8, 1);
}

.text-highlight::after {
  top: 93%;
  background-color: var(--text-highlight-color);
}

.company-highlight::after {
  top: 88%;
  background: linear-gradient(
    to right,
    var(--company-highlight-color-l) 25%,
    var(--company-highlight-color-y) 75%
  );
}

.company-highlight:hover {
  color: var(--bg-color);
}

.company-highlight:hover::after {
  top: 0%;
}

.activity {
  margin: 2em 0 3em;
}

.activity-hover-able {
  background: linear-gradient(90deg, #fff6 50%, transparent 50%);
  background-repeat: repeat-x;
  background-size: 6px 1px;
  background-position: 0 100%;
  position: relative;
  cursor: grab;
  transition: background-size var(--transition);
  max-width: 85%;
}

.activity-hover-able:hover {
  animation: dash-animation 15s infinite linear;
  background-size: 6px 2px;
}

.activity-hover-able:hover::after {
  display: inline-block;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.activity-hover-able:hover[data-animation="game"]::after {
  animation-name: game-animation;
  animation-duration: 1s;
}

.activity-hover-able:hover[data-animation="swirl"]::after {
  animation-name: swirl-animation;
  animation-duration: 1.8s;
}

.activity-hover-able:hover[data-animation="headphone"]::after {
  animation-name: headphone-animation;
  animation-duration: 1s;
}

.activity-hover-able:hover[data-animation="plane"]::after {
  animation-name: plane-animation;
  animation-duration: 2.5s;
}

[data-emoji]::after {
  content: attr(data-emoji);
  padding: 0 4px;
}

@keyframes dash-animation {
  0% {
    background-position: 0 100%;
  }

  100% {
    background-position: 100% 100%;
  }
}

@keyframes wave-animation {
  0% {
    transform: rotate(0deg);
  }

  10% {
    transform: rotate(-10deg);
  }

  20% {
    transform: rotate(12deg);
  }

  30% {
    transform: rotate(-10deg);
  }

  40% {
    transform: rotate(10deg);
  }

  50% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

@keyframes high-five-animation {
  0% {
    transform: translateY(0%) scale(0);
  }

  80% {
    transform: translateY(-100%) scale(1);
    opacity: 1;
  }

  100% {
    transform: translateY(-100%) scale(1);
    opacity: 0;
  }
}

@keyframes game-animation {
  0% {
    transform: rotate(0deg);
  }

  25% {
    transform: rotate(20deg);
  }

  50% {
    transform: rotate(0deg);
  }

  75% {
    transform: rotate(-20deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

@keyframes swirl-animation {
  0% {
    transform: rotate(0deg);
  }

  50% {
    transform: rotate(180deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes headphone-animation {
  0% {
    transform: scale(1);
  }

  25% {
    transform: scale(1.15);
  }

  50% {
    transform: scale(1);
  }

  75% {
    transform: scale(0.85);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes plane-animation {
  0% {
    transform: translate(0, 0) rotate(0deg);
  }
  50% {
    transform: translate(10%, -10%) rotate(-5deg);
  }
  100% {
    transform: translate(20%, -20%) rotate(0deg);
  }
}
